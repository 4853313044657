<!-- 团长申请页面 -->
<template>
  <div class="colonelApplyFor">
    <div class="clone-state-list clr">
      <div
        @click="change(item)"
        :class="item.type == activeState?'active':''"
        class="clone-state-item fl"
        v-for="(item,key) in cloneStateList"
        :key="key"
      >
        <span class="name">{{item.label}}</span>
      </div>
      <Input
        style="width:240px"
        @on-search="search"
        v-model="mobile"
        class="fr"
        search
        clearable
        placeholder="请输入手机号搜索"
      />
    </div>
    <div class="data-list-area clr">
      <div class="item-area fl" v-for="(item,key) in dataList" :key="key">
        <div class="state-area">
          <Alert v-if="item.colonelAuditStatus == 'AUDIT_SUCCESS'" type="success" show-icon>已通过</Alert>
          <Alert v-if="item.colonelAuditStatus == 'AUDIT_FAIL'" type="error" show-icon>已拒绝</Alert>
        </div>
        <div class="content-top clr">
          <div class="lt-area fl">
            <img :src="item.avatar" />
          </div>
          <div class="rt-area fl">
            <div class="name-area" :title="item.nickname">{{item.nickname}}</div>
            <div class="id-area" :title="item.userId">
              <span>ID:</span>
              {{item.userId}}
            </div>
          </div>
        </div>
        <div class="content-center">
          <div class="msg-line clr">
            <div class="label fl">姓&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;名:</div>
            <div class="val fl">{{item.userName}}</div>
          </div>
          <div class="msg-line clr">
            <div class="label fl">年&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;龄:</div>
            <div class="val fl">{{item.age}}</div>
          </div>
          <div class="msg-line clr">
            <div class="label fl">手机号码:</div>
            <div class="val fl">{{item.mobile}}</div>
          </div>
          <div class="msg-line clr">
            <div class="label fl">申请时间:</div>
            <div class="val fl">{{item.colonelCreateTime}}</div>
          </div>
        </div>
        <div class="content-btm clr">
          <template v-if="item.colonelAuditStatus == 'UNAUDIT'">
            <Button @click="agree(item)" class="fr" type="primary" style="margin-left:10px">通过</Button>
            <Button @click="reject(item)" class="fr">拒绝</Button>
          </template>
          <template v-else>
            <Button class="fr" disabled>已处理</Button>
          </template>
        </div>
      </div>
    </div>
    <div class="page-area" v-if="dataList.length">
      <Page
        :total="total"
        :current="current"
        show-total
        :page-size="size"
        @on-change="pageChage"
        @on-page-size-change="pageSizeChage"
      ></Page>
    </div>
    <div v-if="modelShow">
      <Modal v-model="modelShow" :title="'审批'+titleName+'意见'" width="500">
        <auditBox
          ref="auditBox"
          :titleName="titleName"
          v-if="modelShow"
          @close="modelShow = false"
        />
        <div class="demo-drawer-footer">
          <Button style="margin-right: 8px" @click="modelShow = false">取消</Button>
          <Button type="primary" @click="audit">确定</Button>
        </div>
      </Modal>
    </div>
  </div>
</template>

<script>
import { getColonelList, auditApplyColonel } from '@/api/colonelCenter.js'
// import { findMemberPageList } from '@/api/memberCenter.js'
import auditBox from './components/auditBox.vue'

export default {
  components: {
    auditBox
  },
  data() {
    return {
      mobile: '',
      total: 0,
      current: 1,
      size: 20,
      dataList: [],
      cloneStateList: [
        {
          label: '全部',
          type: 'all'
        },
        {
          label: '申请',
          type: 'UNAUDIT'
        },
        {
          label: '通过',
          type: 'AUDIT_SUCCESS'
        },
        {
          label: '拒绝',
          type: 'AUDIT_FAIL'
        }
      ],
      activeState: 'all',
      modelShow: false,
      titleName: '审批同意意见',
      currentData: {}
    }
  },
  created() {
    this.findPageDataList()
  },
  methods: {
    search() {
      this.current = 1
      this.findPageDataList()
    },
    change(item) {
      this.activeState = item.type
      this.current = 1
      this.findPageDataList()
    },
    findPageDataList() {
      let params = { pageIndex: this.current, size: this.size }
      params.mobile = this.mobile
      if (this.activeState != 'all') {
        params.colonelAuditStatusList = [this.activeState]
      }
      getColonelList(params).then(res => {
        if (res.success) {
          this.dataList = res.result.list
          this.total = res.result.total
        }
      })
    },
    pageChage(page) {
      const listContainer = document.querySelector('.data-list-area');
      if (listContainer) {
        listContainer.scrollTop = 0;
      }
      this.current = page
      this.findPageDataList()
    },
    pageSizeChage(size) {
      this.size = size
      this.findPageDataList()
    },
    agree(item) {
      this.currentData = item
      this.titleName = '同意'
      this.modelShow = true
    },
    reject(item) {
      this.currentData = item
      this.titleName = '拒绝'
      this.modelShow = true
    },
    audit() {
      this.modelShow = false
      let params = {}
      let msg = ''
      if (this.titleName == '同意') {
        params.auditStatus = 'AUDIT_SUCCESS'
        msg = '审核通过！'
      } else {
        params.auditStatus = 'AUDIT_FAIL'
        msg = '已拒绝申请！'
      }
      params.id = this.currentData.id
      params.userId = this.currentData.userId
      params.auditMsg = this.$refs.auditBox.formData.msg
      params.pushFlag = this.$refs.auditBox.formData.agree
      auditApplyColonel(params).then(res => {
        if (res.success) {
          this.$Message.success(msg)
          this.findPageDataList()
          this.modelShow = false
        }
      })
    }

  }
}
</script>

<style lang='scss'>
.colonelApplyFor {
  height: calc(100vh - 150px);
  .clone-state-list {
    margin-bottom: 16px;
    .clone-state-item {
      padding: 0 25px;
      height: 30px;
      line-height: 30px;
      cursor: pointer;
    }
    .clone-state-item.active {
      color: #1664ff;
      background: #f2f3f8;
      border-radius: 15px;
    }
  }
  .data-list-area {
    padding-right: 10px;
    height: calc(100% - 100px);
    overflow: auto;
    .item-area {
      position: relative;
      padding: 10px;
      width: calc(25% - 15px);
      height: 208px;
      margin-right: 20px;
      margin-bottom: 15px;
      border: 1px solid #e5e8ef;
      border-radius: 4px;
      .state-area {
        position: absolute;
        top: 8px;
        right: 8px;
        .ivu-alert.ivu-alert-with-icon {
          padding: 4px 5px 4px 20px;
          border: none;
        }
        .ivu-alert-icon {
          top: 4px;
          left: 2px;
        }
        .ivu-alert-message {
          font-size: 12px;
          color: #00b42a;
        }
        .ivu-alert-error {
          .ivu-alert-message {
            color: #f53f3f;
          }
        }
      }
      .content-top {
        .lt-area {
          margin-right: 12px;
          width: 36px;
          height: 36px;
          border-radius: 50%;
          overflow: hidden;
          img {
            display: block;
            width: 100%;
            height: 100%;
          }
        }
        .rt-area {
          width: calc(100% - 120px);
          .name-area {
            width: 100%;
            height: 21px;
            margin-bottom: 3px;
            font-size: 14px;
            color: #000;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
          .id-area {
            width: 100%;
            font-size: 12px;
            color: #86909c;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
        }
      }
      .content-center {
        padding: 15px 0;
        height: 110px;
        font-size: 12px;
        line-height: 20px;
        .label {
          width: 60px;
        }
        .val {
          width: calc(100% - 100px);
          color: #1d2129;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
    }
    .item-area:nth-child(4n) {
      margin-right: 0;
    }
  }
  .page-area {
    margin-top: 15px;
  }
}
</style>