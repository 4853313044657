<template>
  <div>
    <Form ref="formInline" :model="formData">
      <FormItem prop>
        <Input type="textarea" v-model="formData.msg" :rows="6"></Input>
      </FormItem>
    </Form>
    <Checkbox v-model="formData.agree">审批意见发送至APP给该用户</Checkbox>
  </div>
</template>

<script>
export default {
  props: {
    titleName: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      formData: {
        msg: '',
        agree: true
      }
    }
  },
  created() {
    if (this.titleName == '同意') {
      this.formData.msg = '恭喜您申请的团长资格通过审批，已开通发布活动权限，在【我的-团长中心】可发布活动'
    } else {
      this.formData.msg = '很遗憾，您的申请团长未被审批，如对结果不满，请发送邮件至dagequan@163.com详细说明'
    }
  },

}
</script>

<style>
</style>